const initState = () => ({
  showFilter: false,
  categories: [],
})

export const state = initState

export const getters = {}

export const mutations = {
  setCategories(state, {categories}) {
    state.categories = categories
  },
  reset(state) {
    Object.assign(state, initState())
  },
}

export const actions = {
  async getCategories({commit}) {
    const categories = await this.$axios.$get('/ProductCategories')
    commit('setCategories', {categories})
  },
  async getChildCategories({commit}, parentID) {
    const categories = await this.$axios.$get(
      '/ProductCategories/childProductCatalogs/' + parentID
    )
    commit('setCategories', {categories})
  },
  async fetchChildCategories({commit}, parentID) {
    return await this.$axios.$get(
      '/ProductCategories/childProductCatalogs/' + parentID
    )
  },
}
