export const gridCodes = {
  PriceQuote: 'CPv3_PriceQuoteGrid',
  JobOrder: 'CPv3_JobOrderGrid',
  SalesOrder: 'CPv3_SalesOrderGrid',
  Invoice: 'CPv3_InvoiceGrid',
  Payment: 'CPv3_PaymentGrid',
  CustomerCredit: 'CPv3_CustomerCreditGrid',
  Contents: 'CPv3_Contents',
  Components: 'CPv3_Components',
  ProductCatalog: 'CPv3_ProductCatalog',
  Catalog: 'CPv3_Catalog',
  Products: 'CPv3_ProductsGrid',
}


