
const initState = () => ({
  customerFinancialSettings: {},
})
export const state = initState
export const mutations = {
  setCustomerFinancialSettings(state, {customerFinancialSettings}) {
    state.customerFinancialSettings = customerFinancialSettings
  },
}
export const getters = {
  getCustomerFinancialSettings(state) {
    return state.customerFinancialSettings
  },
}
export const actions = {
  async getCustomerFinancialSetting({commit}) {
    const customerFinancialSettingResponse = await this.$axios.$get('/customerfinancialsetting')
    if(customerFinancialSettingResponse){
      if(customerFinancialSettingResponse.hasErrors){
        customerFinancialSettingResponse.errorMessages.map((error, index) =>{
          this.$toast.error(error.value)
        })
      }else{
        commit('setCustomerFinancialSettings', {customerFinancialSettings: customerFinancialSettingResponse.value})
      }
    }
  },
  async getHasCustomerDiscountOrMarkupByItemType({commit}, {itemTypeId}){
    const response = await this.$axios.$get(`/customers/has-customer-discount-or-markup-by-item-type/${itemTypeId}`)
    if(response.hasErrors === false){
      if(response.value){
        return response.value.hasDiscountOrMarkup
      } else {
        return false
      }
    } else {
      if(response.errorMessages){
        response.errorMessages.map((error, index) =>{
          this.$toast.error(error.value)
        })
      }
      return false
    }
  },
}
