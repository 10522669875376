const initState = () => ({
  invoices: {},
  options: {},
  page: 1
})

export const state = initState

export const getters = {
}

export const mutations = {
  setInvoices(state, {invoices}) {
    state.invoices = invoices
  },
  setOptions(state, {options}) {
    state.options = options
  },
  setPage(state, {page}) {
    state.page = page
  },
  updateInvoiceItemAppliedAmountWithBalance(state, item) {
    if(state.invoices.items) {
      state.invoices.items.map(invoiceItem => {
        if(invoiceItem.id === item.id)
          invoiceItem.appliedAmount = item.balance
      })
    }
  }
}

export const actions = {
  async getInvoices({commit}) {
    const invoices = await this.$axios.$get('/invoices')
    commit('setInvoices', {invoices})
  },
  async getFilteredInvoices({commit}, {filters}) {
    let url = `/invoices`;
    filters.map((f) => {
      if(f.value || f.type === 'checkbox') {
        if (url.includes('?')) {
          url = url + `&${f.property}=${f.value}`
        } else {
          url = url + `?${f.property}=${f.value}`
        }
      }
    })
    const invoices = (await this.$axios.$get(url))
    commit("setInvoices", {invoices})
  },
  generateInvoicePDFReport({commit}, invoiceId) {
    this.$axios.get(`/invoices/generateInvoicePDFReport/${invoiceId}`, {responseType: 'blob'}).then(response => {
      console.log(response)
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
      window.open(url, "_blank");
      /*const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.click();*/
    })
  }

}
