const initState = () => ({
  customerCredits: [],
  page: 1,
  totalItems: 0,
  options: {},
})

export const state = initState

export const mutations = {
  setCustomerCredits(state, {customerCredits}) {
    state.customerCredits = customerCredits
  },
  setOptions(state, {options}) {
    state.options = options
  },
  setTotalItems(state, {totalOrders}) {
    state.totalItems = totalOrders
  },
}

export const actions = {
  async fetchCustomerCredits({commit, state}, {filters}) {
    const {page, itemsPerPage} = state.options
    const result = await this.$axios.$get(
      '/CustomerCredit?pageSize=' + itemsPerPage + '&pageNo=' + page + filters
    )
    commit('setCustomerCredits', {customerCredits: result.items})
    commit('setTotalItems', {totalOrders: result.pagingData.itemCount})

    return result
  },
}
